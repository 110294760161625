import { withPageData } from "../../utils/common-page-data/common-page-data";
import { getNewCampaignPageProps } from "../digitalcampaign/getServerSideProps";
import DigitalCampaignPage from "../digitalcampaign/[slug]";

/**
 * Campaign page for Sweden
 */
export default DigitalCampaignPage;

export const getServerSideProps = withPageData<boolean>(
  getNewCampaignPageProps
);
